import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>           Welcome to Furrer Industries.         </p>
      </header>
    </div>
  );
}

export default App;
